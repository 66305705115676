(function(window, google){
    "use strict";

    /*==========================================================================
        Global variables
    ==========================================================================*/
    var common = {
        accordionData: null,
        accordionId: 'buildings-accordion',
        admissions: [],
        arts_music: [],
        athletics: [],
        atm: [],
        autoCompleteNames: [],
		bikerepair: [],
        buildingFillColor: null,
        buildingNames: [],
        buildingPolygons: [],
        buildings: [],
        colleges: [],
        directionsDisplay: null,
        emergency_phones: [],
		evcharging: [],
        food: [],
        gardens: [],
        highlightBuildingOutline: [],
        housing: [],
        infoBox: null,
        library: [],
        locateMeMarker: null,
        mail: [],
        mainCampusUrl: './data/map/mainLocationData.json', //
        markers: [],
        medical: [],
        otherLocations: null,
        otherLocationsUrl: './data/map/otherLocationsData.json',
        parking: [],
        poiColor: null,
		printcopy: [],
		restrooms: [],
        shopping: [],
        showBuildingOutline: [],
        student_success: [],
        security: [],
        trolley: [],
		waterbottles: [],
        buildingListButton: null,
        sideNavElements: null
    };

    /*==========================================================================
        Map Options
    ==========================================================================*/
    var overlays = '/dist/images/';
    var options = {
        center: {
            lat: 28.064,
            lng: -80.623
        },
        zoom: 18,
        zoomControlOptions: {
                style: google.maps.ZoomControlStyle.LARGE,
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
        mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.LEFT_TOP
            },
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        streetViewControl: true,
        tilt: 0,
        styles:[
            {   featureType: "administrative", stylers: [ { visibility: "off" } ] },
            {   featureType: "landscape.man_made", stylers: [ { visibility: "off" } ] },
            {   featureType: "poi", elementType: "labels", stylers: [{ visibility: "off"}]},
            {   featureType: "landscape", stylers: [{ color: "#d9ddc3" } ] },
            {   featureType: "transit", stylers: [ { visibility: "off" } ] },
            {   featureType: "road.local", stylers: [ { visibility: "simplified" } ] },
            {   featureType: "poi.school", elementType: "geometry.fill", stylers: [{ color: "#c2cca4" }] }
        ],
        overlays: [
            {
                bounds: {
                    sw: generateLatLng(28.0579144, -80.62599),
                    ne: generateLatLng(28.07042, -80.61971)
                },
                image: '/css/images/main-campus-overlay.jpg',
                options: {
                    clickable: false,
                    opacity: 1,
                    isPNG: true,
                    zIndex: 5
                }
            },
            {
                bounds: {
                    sw: generateLatLng(28.075006, -80.622173),
                    ne: generateLatLng(28.076485, -80.621417)
                },
                image: '/dist/images/cob-overlay.png',
                options: {
                    clickable: false,
                    opacity: 1,
                    isPNG: true
                }
            },
            {
                bounds: {
                    sw: generateLatLng(28.047821, -80.621384),
                    ne: generateLatLng(28.049549, -80.619877)
                },
                image: '/dist/images/panther-stadium-overlay.png',
                options: {
                    clickable: false,
                    opacity: 1,
                    isPNG: true
                }
            }
        ],
        otherLocationsOverlayOptions: {
            clickable: false,
            opacity: 1,
            isPNG: true
        },
        polygon: {
            poi:{
                strokeColor: '#009fda',
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
                fillOpacity: 0.3,
                clickable: false,
                zIndex:1
            },
            building:{
                strokeColor: 'transparent',
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: 'transparent',
                fillOpacity: 0.4,
                clickable: true,
                zIndex:-1
            },
            activeBuilding:{
                strokeColor: '#770000',
                strokeOpacity: 1,
                strokeWeight: 6,
                fillColor: 'transparent',
                fillOpacity: 0,
                clickable: true,
                zIndex: -1
            }
        },
        infoBoxOptions: {
            disableAutoPan: false,
            maxWidth: 0,
            pixelOffset: new google.maps.Size(-140, 0),
            zIndex: 2,
            boxStyle: {
                background: "url('tipbox.gif') no-repeat",
                minWidth: "200px",
                maxWidth: "300px",
                height: "auto"
            },
            closeBoxMargin: "10px 4px 2px 2px",
            closeBoxURL: "/dist/images/closeBtn.png",
            infoBoxClearance: new google.maps.Size(1, 1),
            isHidden: false,
            pane: "floatPane",
            enableEventPropagation: false
        },
        geolocation: {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        }
    },

    element = document.getElementById('map-canvas'),
    map = new google.maps.Map(element, options);

    /*==========================================================================
        Initialize the infoBox Window
    ==========================================================================*/
    common.infoBox = new InfoBox(options.infoBoxOptions);

    /*==========================================================================
        Initialize application functions
    ==========================================================================*/
    function init() {
        loadLocationsData(common.mainCampusUrl, initMainCampus); //
        loadLocationsData(common.otherLocationsUrl, initOtherLocations);
        showPointsOfInterest();
        initSideMenu();
        initAutoCompleteSearch();
        initAccordion();
        initSecurityModal();
        showNearestTrolleyStop();
        mobileFindMeLoader();
        setActiveMenuItem('side-menu', 'mainLocationSelect');
        setActiveMenuItem('side-menu', 'otherLocationSelect');
        setActiveBldgMenuItem();
        multiElementClickHandler('centerMap', mapReset);
        multiElementClickHandler('userLocationBtn', locateMe);
        multiElementClickHandler('searchMap', showsearchFormMenu);
    }

    /*==========================================================================
        Get application API data
    ==========================================================================*/
    function loadLocationsData(url, callback){
        var APIData, records;
        url = url;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onload = function(){
            if(this.status === 200) {
                APIData = JSON.parse(this.responseText);
                records = (APIData.records);
                callback(records);
            }
        };
        xhr.send();
    }

    /*==========================================================================
        Provide data and initialize components
    ==========================================================================*/
    function loadBuildingPolygonData(buildings, data){
        common.buildingPolygons.push(data);
    }

    function initMainCampus(data) {
        parsePolygonAndPoiData(data);
        createBuildingListData(data);
        showBuildings();
    }

    function initOtherLocations(data) {
        common.otherLocations = (data);
        showOtherLocations();
    }

    /*==========================================================================
        Parse data for polygons and POIs
    ==========================================================================*/
    function parsePolygonAndPoiData(records){
        var pointsOfInterests;
        for (var i = 0; i < records.length; i++) {
            pointsOfInterests = {
                id: records[i].id,
                name: records[i].name,
                code: records[i].building ? records[i].building.code : null,
                website: records[i].website,
                description: records[i].description,
                address: records[i].address,
                photo: records[i].photo,
                building: records[i].is_building,
                buildingApiData: records[i].building,
                poi: records[i].is_poi,
                outline: records[i].outline,
                trolley_stop: records[i].nearest_trolley_stop,
                location: { lat: Number(records[i].location.lat), lng: Number(records[i].location.lng) },
                type: records[i].type,
                type_name: records[i] && records[i].type ? records[i].type.name : null,
                type_icon: records[i] && records[i].type ? records[i].type.icon : null,
                type_color: records[i] && records[i].type ? records[i].type.color : null,
            };

            // Use building names from Directories API vs database building names
            if (pointsOfInterests.building && typeof pointsOfInterests.buildingApiData !== 'undefined') {
                // override - we want to use the database name instead of the actual building name for shaw hall dorm and security office
                if (parseInt(records[i].id) !== 39){
                    pointsOfInterests.name = (pointsOfInterests.buildingApiData.name || pointsOfInterests.name);
                }
            }

            // Change incorrect or missing location Lat, Lng
            updateLocationsWithoutLatLng(pointsOfInterests);
            // Convert lat, lng from string to number
            convertPolygonLatLngToNumber(pointsOfInterests.outline);
            // Assign data to points of interest
            loadPologonAndPoiData(pointsOfInterests);
        }
    }

    /*==========================================================================
        Load and structure POI data
    ==========================================================================*/
    function loadPointsOfInterestData(POIType, data){
        switch (POIType) {
            case 'Security': common.security.push(data); data.type_icon = '\uf3ed'; break;
            case 'Student Success': common.student_success.push(data); data.type_icon = '\uf19d'; break;
            case 'Health': common.medical.push(data); data.type_icon = '\uf0fa'; break;
            case 'Emergency Call Boxes': common.emergency_phones.push(data); data.type_icon = '\uf095'; break;
            case 'Colleges': common.colleges.push(data); data.type_icon = '\uf19c'; break;
            case 'Library': common.library.push(data); data.type_icon = '\uf02d'; break;
            case 'Arts and Music': common.arts_music.push(data); data.type_icon = '\uf001'; break;
            case 'Athletics': common.athletics.push(data); data.type_icon = '\uf1b0'; break;
            case 'Parking': common.parking.push(data); data.type_icon = '\uf1b9'; break;
            case 'Food': common.food.push(data); data.type_icon = '\uf2e7'; break;
            case 'Shopping': common.shopping.push(data); data.type_icon = '\uf07a'; break;
            case 'Mail': common.mail.push(data); data.type_icon = '\uf0e0'; break;
            case 'ATM': common.atm.push(data); data.type_icon = '\uf09d'; break;
            case 'Housing': common.housing.push(data); data.type_icon = '\uf015'; break;
            case 'Botanical Garden': common.gardens.push(data); data.type_icon = '\uf1bb'; break;
            case 'Trolley Stops': common.trolley.push(data); data.type_icon = '\uf238'; break;
            case 'Admissions': common.admissions.push(data); data.type_icon = '\uf0a3'; break;
			case 'Single Occupancy Restroom': common.restrooms.push(data); data.type_icon = '\uf29a'; break;
			case 'Print-Copy Location': common.printcopy.push(data); data.type_icon = '\uf02f'; break;
			case 'Water Bottle Fill Station': common.waterbottles.push(data); data.type_icon = '\uf043'; break;
			case 'Electric Vehicle Charging': common.evcharging.push(data); data.type_icon = '\uf0e7'; break;
			case 'Bicycle Repair Station': common.bikerepair.push(data); data.type_icon = '\uf206'; break;
        }
    }

    /*==========================================================================
        Structure and render sidebar accordion data
    ==========================================================================*/
    function createBuildingListData(data){
        var buildingDataList;
        var buildingSidebarData = [];
        var testLocations = data;
        var buildingsAB = [],
            buildingsCE = [],
            buildingsFG = [],
            buildingsHJ = [],
            buildingsKO = [],
            buildingsPR = [],
            buildingsSZ = [];
        var letterAB = /^[0-9a-bA-B]+$/,
            letterCE = /^[c-eC-E]+$/,
            letterFG = /^[f-gF-G]+$/,
            letterHJ = /^[h-jH-J]+$/,
            letterKO = /^[k-oK-O]+$/,
            letterPR = /^[p-rP-R]+$/,
            letterSZ = /^[s-zS-Z]+$/;

        testLocations.forEach(function (building) {
            // Use building names from Directories API vs database building names
            if (building.building) {
                building.name = (building.building.name || building.name);
            }

            updateLocationsWithoutLatLng(building);
            // Check for locations with is_building set to true
            if(building.is_building) {
                // If location does not have buiding code assign building ID as building code
                if(!building.building){
                    building.building = {};
                    building.building.code = building.id;
                }

                buildingSidebarData.push(building);
            }

            if (building.is_building === true) {

                if (letterBeginsWith(building.name, letterAB)) {
                    buildingsAB.push(building);
                }
                if (letterBeginsWith(building.name, letterCE)) {
                    buildingsCE.push(building);
                }
                if (letterBeginsWith(building.name, letterFG)) {
                    buildingsFG.push(building);
                }
                if (letterBeginsWith(building.name, letterHJ)) {
                    buildingsHJ.push(building);
                }
                if (letterBeginsWith(building.name, letterKO)) {
                    buildingsKO.push(building);
                }
                if (letterBeginsWith(building.name, letterPR)) {
                    buildingsPR.push(building);
                }
                if (letterBeginsWith(building.name, letterSZ)) {
                    buildingsSZ.push(building);
                }
            }
        });

        buildingDataList = {
            AB: buildingsAB,
            CE: buildingsCE,
            FG: buildingsFG,
            HJ: buildingsHJ,
            KO: buildingsKO,
            PR: buildingsPR,
            SZ: buildingsSZ
        };

        listSort(buildingDataList.AB);
        listSort(buildingDataList.CE);
        listSort(buildingDataList.FG);
        listSort(buildingDataList.HJ);
        listSort(buildingDataList.KO);
        listSort(buildingDataList.PR);
        listSort(buildingDataList.SZ);

        // Generate and append sidebar accordion template
        renderTemplate('accordionTemplate', common.accordionId, buildingDataList);
        // Show building on click of accordion list link
        selectBuildingToShow(buildingSidebarData);
        // Go to building based on URL get variable
        showBuildingFromLink(buildingSidebarData);
    }

    /*==========================================================================
      Sidebar accordion functionality
    ==========================================================================*/
    function initAccordion() {
        var headers,
            subItem,
            depth,
            allAtDepth;

        headers = ["H2"];

        $(".mapAccordion").click(function (e) {
            var target = e.target,
                name = target.nodeName.toUpperCase();

            if ($.inArray(name, headers) > -1) {
                subItem = $(target).next();
                depth = $(subItem).parents().length;
                allAtDepth = $(".accordion p, .accordion div").filter(function () {
                    if ($(this).parents().length >= depth && this !== subItem.get(0)) {
                        return true;
                    }
                });
                $(allAtDepth).slideUp("slow");
                subItem.slideToggle("slow", function () { });
                $(target).css({ "border-bottom-right-radius": "0", "border-bottom-left-radius": "0" });
            }
        });
    }

    /*==========================================================================
        Load polygon and POI
    ==========================================================================*/
    function loadPologonAndPoiData(data) {
        // Populate building information for auto search
        loadAutocompleteData(data);
        // Populate building polygon data
        loadBuildingPolygonData(data.outline, data);
        // Populate POI data
        loadPointsOfInterestData(data.type_name, data);
    }

    function loadAutocompleteData(data) {
        var autocomplete = data;
        common.autoCompleteNames.push({
            type: 'location',
            value: autocomplete.code ? autocomplete.name + ' - ' + autocomplete.code : autocomplete.name,
            data: data
        });
    }

    /*==========================================================================
        Use the location array to create an array of markers.
    ==========================================================================*/
    function markerShow (locationsType){
        var position, name,website, description, address,
            photo,text_icon, type_color, icon, marker;
        var showMark = function() { populateInfoWindow(this, common.infoBox); };

        for (var i = 0; i < locationsType.length; i++) {
            position = locationsType[i].location;
            name = locationsType[i].name;
            website = locationsType[i].website;
            description = locationsType[i].description;
            address = locationsType[i].address;
            photo = locationsType[i].photo;
            text_icon = locationsType[i].type_icon;
            type_color = locationsType[i].type_color;
            icon = {
                path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
                fillColor: type_color || '#fff',
                fillOpacity: 1,
                anchor: new google.maps.Point(0,0),
                strokeWeight: 2,
                strokeColor: '#20221C',
                scale: 1,
                labelOrigin: new google.maps.Point(0,-30)
            };

            if(position.lat != 0) {
                marker = new google.maps.Marker({
                    position: position,
                    name: name,
                    website: website,
                    description: description,
                    address: address,
                    photo: photo,
                    icon: icon,
                    label: {
                        fontFamily: "Fontawesome",
                        text: text_icon || '\uf13a',
                        color: "#20221C",
                        fontSize: "1.2rem",
                      },
                    id: i
                });

                common.markers.push(marker);

                if(description != null) {
                    marker.addListener('click', showMark);
                }
            }
        }

    }

    /*==========================================================================
        Side Menu click handlers
    ==========================================================================*/
    function initSideMenu() {
        common.buildingListButton = document.getElementById('building-list-btn');

        common.buildingListButton.addEventListener('click', showBuildingList);
        document.getElementById('building-list-close-btn').addEventListener('click', hideBuildingList);
        document.getElementById('other-locations-btn').addEventListener('click', showOtherLocationsMenu);
        document.getElementById('search-form-close-btn').addEventListener('click', hidesearchFormMenu);
        document.getElementById('other-location-close-btn').addEventListener('click', hideOtherLocationsMenu);
    }

    /*==========================================================================
        Shows a modal with a message from the security office
    ==========================================================================*/
    function initSecurityModal(){
        $('.securityModal.modal').modal('show');
    }

    /*==========================================================================
        Sidebar Points of interest Button Click Event Handlers
    ==========================================================================*/
    function showPointsOfInterest(event) {
        var mainPOIMapLocations = document.querySelectorAll('.mainLocationSelect');
        mainPOIMapLocations = Array.from(mainPOIMapLocations);
        mainPOIMapLocations.forEach(function (pointsOfInterest) {
            pointsOfInterest.addEventListener('click', function (e) {
                var id = e.target.id.slice(0, -1);
                if(id){
                    showListings(common[id]);
                }
            });
        });
    }

    /*==========================================================================
        Recenter map, search map and Find me click handlers
    ==========================================================================*/
    // Function for dual-button elements - Reset, Find Me and Search
    function multiElementClickHandler(elementClassName, callFunction) {
        var element = document.getElementsByClassName(elementClassName);
        var elementArray = Array.from(element);
        elementArray.forEach(function (action) {
            action.addEventListener('click', callFunction);
        });
    }

    /*==========================================================================
        Click event to show closest trolley stops
    ==========================================================================*/
    function showNearestTrolleyStop() {
        var trolleyData = [];
        document.getElementById('map-canvas').addEventListener('click', function(e){
            if (event.target.classList.contains('nearest-trolley')) {
                common.buildingNames.forEach(function(building, index){
                    closeInfoBox();
                    if(e.target.id === building.data.id) {
                        trolleyData.push(building.data);
                        showListings(trolleyData);
                        trolleyData = [];
                    }
                });
            }
        });
    }

    /*==========================================================================
        Populate the infoBox Window
    ==========================================================================*/
    function populateInfoWindow(marker, infowindow) {
        var boxText = document.createElement("div");
        boxText.style.cssText = "border: 1px solid black; margin-top: 8px; background: #fff; ";
        renderMarkerTemplate('markerTemplate', boxText, marker);
        infowindow.setContent(boxText);
        infowindow.open(map, marker);
    }

    /*==========================================================================
        Loop through the markers array and display them all
    ==========================================================================*/
    function showListings(type) {
        resetDirections();
        deleteMarkers();
        hidePolygon();
        markerShow(type);
        var bounds = new google.maps.LatLngBounds();

        // Extend the boundaries of the map for each marker and display the marker
        for (var i = 0; i < common.markers.length; i++) {
            common.markers[i].setMap(map);
            bounds.extend(common.markers[i].getPosition());
        }

        map.fitBounds(bounds);

        if(map.getZoom()> 18){
            map.setZoom(17);
        }
    }

    /*==========================================================================
        Show other locations on click
    ==========================================================================*/
    function showOtherLocations(){
        var otherMapLocations = document.querySelectorAll('.otherLocationSelect');
        // Turn node list into traditional array
        otherMapLocations = Array.from(otherMapLocations);
        // Cycle through node array and add click events
        otherMapLocations.forEach(function(locals){
            locals.addEventListener('click', function (e) {
                // Iterate through API data and match click targets with api data name
                for (var i = 0; i < common.otherLocations.length; i++) {
                    if (e.target.id === common.otherLocations[i].name){
                        // If match set map center to location of API lat, lng
                        map.setCenter(generateLatLng(common.otherLocations[i].location.lat, common.otherLocations[i].location.lng));
                        map.setZoom(18);
                    }
                }
            });
        });
    }

    /*==========================================================================
        Allow map to got to selected building from accordion list on click
    ==========================================================================*/
    function selectBuildingToShow(buildingData){
        var buildingListLocations = document.getElementById('buildings-accordion');
        buildingListLocations.addEventListener('click', function (e) {
            // Iterate through API data and match click targets with api data name
            console.log(_.unescape(e.target.id))
            for (var i = 0; i < buildingData.length; i++) {
                // if (_.unescape(e.target.innerHTML) === buildingData[i].name) {
                    if (e.target.id === buildingData[i].building.code) {
                    console.log(buildingData[i].building.code)
                    // If match set map center to location of API lat, lng
                    map.setCenter(generateLatLng(buildingData[i].location.lat, buildingData[i].location.lng));
                    map.setZoom(18);
                    highlightBuilding(buildingData[i].outline, buildingData[i].building.code);
                }
            }
        });
    }

    /*==========================================================================
        Loop through the listings show building markers
    ==========================================================================*/
    function showBuildingFromLink(buildingData){
        var vars = getUrlVars();
        var id = vars['location'];
        for (var i = 0; i < buildingData.length; i++) {
            if ((id) === buildingData[i].building.code) {

                // If match set map center to location of API lat, lng
                map.setCenter(generateLatLng(buildingData[i].location.lat, buildingData[i].location.lng));
                map.setZoom(18);
                highlightBuilding(buildingData[i].outline, buildingData[i].building.code);
            }
        }
    }

    /*==========================================================================
        Loop through the listings show building markers
    ==========================================================================*/
    function showBuildings(){
        var labelMarkers = [];
        var buildingInfo, marker, polygonOptions, getBuildings, buildingFillColor;
        var hidePoly = function (){ hidePolygon(); };
        var showBldg = function(event) {
            populateInfoWindow(this.marker, common.infoBox);
            highlightBuilding(this.buildingPath, this.code);
        };

        for(var i = 0; i < common.buildingPolygons.length; i++) {
            buildingInfo = {
                position: common.buildingPolygons[i].location,
                name: common.buildingPolygons[i].name,
                website: common.buildingPolygons[i].website,
                description: common.buildingPolygons[i].description,
                address: common.buildingPolygons[i].address,
                photo: common.buildingPolygons[i].photo,
                code: (common.buildingPolygons[i].code || common.buildingPolygons[i].id),
                building: common.buildingPolygons[i].building,
                buildingPath: common.buildingPolygons[i].outline,
                buildingApiData: common.buildingPolygons[i].buildingApiData,
                trolley: common.buildingPolygons[i].trolley_stop,
                locationType: common.buildingPolygons[i].type,
                typeName: common.buildingPolygons[i].type_name,
                typeColor: common.buildingPolygons[i].type_color,
            };

            // Use building names from Directories API vs database building names
            if (buildingInfo.building && typeof buildingInfo.buildingApiData !== 'undefined') {
                // override - we want to change the name of shaw hall to include the security office
                if (parseInt(common.buildingPolygons[i].id) !== 39){
                    buildingInfo.name = (buildingInfo.buildingApiData.name || buildingInfo.name);
                }
            }

            common.showBuildingOutline = common.buildingPolygons[i].outline;

            if (buildingInfo.position.lat != 0) {
                marker = new google.maps.Marker({
                    position: buildingInfo.position,
                    name: buildingInfo.name,
                    website: buildingInfo.website,
                    description: buildingInfo.description,
                    address: buildingInfo.address,
                    photo: buildingInfo.photo,
                    trolley: buildingInfo.trolley,
                    id: i
                });
                common.markers.push(marker);
            }

            addMapLabels(buildingInfo);
            addColorToLocations(buildingInfo.name);

            polygonOptions = {
                paths: common.showBuildingOutline,
                marker: marker,
                strokeColor: '#000',
                strokeOpacity: 1,
                strokeWeight: 0.6,
                fillColor: common.poiColor,
                fillOpacity: 1,
                position: buildingInfo.position,
                buildingPath: buildingInfo.buildingPath,
                name: buildingInfo.name,
                trolley: buildingInfo.nearest_trolley_stop,
                code: buildingInfo.code,
                website: buildingInfo.website,
                description: buildingInfo.description,
                address: buildingInfo.address,
                photo: buildingInfo.photo,
                anchor: new google.maps.Point(0, 0),
                labelOrigin: new google.maps.Point(0, -30),
                id: i
            };

            if (common.showBuildingOutline && buildingInfo.building === true){
                getBuildings = new google.maps.Polygon(polygonOptions);
                getBuildings.setOptions({zIndex:0});
                getBuildings.setMap(map);
            }

            // google.maps.event.addListener(getBuildings,"click",function(){
            //  this.setOptions({fillColor: "#00FF00"});
            // });

            common.infoBox.addListener('click', hidePoly);
            // Add a listener for the click event.
            if (buildingInfo.description != null) {
                getBuildings.addListener('click', showBldg);
            }
        }
    }

    function addColorToLocations(name){
        switch (name){
                case 'Andy Seminick Baseball Field':
                case 'Stottler Soccer Field':
                case 'Nancy Bottge Varsity Softball Field':
                case 'Intramural Practice Field':
                case 'Intramural Sports Field':
                case 'Anthony J. Catanese Varsity Training Center':
                case 'Tennis Courts':
                case 'Florida Tech Panther Stadium':
                    common.poiColor = '#9DCE60';
                    break;
                default:
                    common.poiColor = '#FCBC3D';
                    break;
            }
    }

    function addMapLabels(buildingInfo){
        if(buildingInfo.building) {
                var polyLabel1 = new MapLabel({
                    text: buildingInfo.name + '  ' + buildingInfo.code,
                    position: new google.maps.LatLng(buildingInfo.position),
                    map: map,
                    minZoom: 20,
                    zIndex: 401,
                    fontFamily: 'Open sans',
                    fontSize: 15,
                    align: 'center',
                    strokeWeight: 6,
                });

                var polyLabel2 = new MapLabel({
                    text: buildingInfo.name + '  ' + buildingInfo.code,
                    position: new google.maps.LatLng(buildingInfo.position),
                    map: map,
                    minZoom: 18,
                    maxZoom: 19,
                    zIndex: 401,
                    fontFamily: 'Open sans',
                    fontSize: 11,
                    align: 'center',
                    strokeWeight: 5,
                });
            }
    }

    /*==========================================================================
       User Geolocation functions
    ==========================================================================*/
    function locateMe(){
        if(navigator.geolocation){
            document.querySelector(".findLocation").style.display = "none";
            document.querySelector(".saving").style.display = "block";
            navigator.geolocation.getCurrentPosition(geoLocationSuccess, geoLocationError, options.geolocation);
        } else {
            showErrorMessage('Geolocation is not supported by your browser.');
        }
    }

    function geoLocationSuccess(position){
        var myLatLng,
            icon,
            icon_text;
                myLatLng = {lat: position.coords.latitude, lng: position.coords.longitude};
                map.setCenter(generateLatLng(position.coords.latitude, position.coords.longitude));
                icon = {
                    path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
                    fillColor: '#000',
                    fillOpacity: 1,
                    anchor: new google.maps.Point(0,0),
                    strokeWeight: 2,
                    strokeColor: '#fff',
                    scale: 1,
                    labelOrigin: new google.maps.Point(0,-30)
                };

                icon_text = '\uf21d';
                common.locateMeMarker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    title: "I'm Here",
                    icon:icon,
                    label: {
                        fontFamily: "Fontawesome",
                        text: icon_text,
                        color: "#fff",
                        fontSize: "1.2rem",
                      },
                });
                document.querySelector(".findLocation").style.display = "block";
                document.querySelector(".saving").style.display = "none";
                document.querySelector(".savingSmall").style.display = "none";
    }

    function geoLocationError(error) {
        showErrorMessage('Unable to find your location. Make sure location services is turned on.');
    }

    function showErrorMessage(message) {
        var modalMessage = document.getElementById('error-modal-msg');
        modalMessage.innerHTML = message;
        $('.ui.modal.errorModal').modal({blurring: true}).modal('show');
        hideFindMeLoader();
    }

    function mobileFindMeLoader() {
        document.getElementById("find-me-side").addEventListener('click', function(){
            document.querySelector(".savingSmall").style.display = "block";
        });
    }

    function hideFindMeLoader() {
        document.querySelector(".saving").style.display = "none";
        document.querySelector(".savingSmall").style.display = "none";
        document.querySelector(".findLocation").style.display = "block";
    }

    /*==========================================================================
        Autocomplete search function
    ==========================================================================*/
    function initAutoCompleteSearch(){
        var markerData;
        $('#autocomplete').autocomplete({
            lookup: common.autoCompleteNames,
            onSelect: function (suggestion) {
                markerData = [suggestion.data];
                deleteMarkers();
                hidePolygon();
                switch (suggestion.type) {
                    case 'location': {
                        if (suggestion.data.building === true &&
                            (suggestion.data.outline !== null &&
                            suggestion.data.outline !== "")) {
                            highlightBuilding(suggestion.data.outline, suggestion.data.code);
                        }

                        if (suggestion.data.building === true &&
                            (suggestion.data.outline === null ||
                            suggestion.data.outline === "")) {
                            showListings(markerData);
                        }

                        if (suggestion.data.poi === true &&
                            (suggestion.data.outline === null ||
                            suggestion.data.outline === "")) {
                            showListings(markerData);
                        }

                        if (suggestion.data.poi === true &&
                            (suggestion.data.outline !== null &&
                            suggestion.data.outline !== "")) {
                            highlightBuilding(suggestion.data.outline, suggestion.data.code);
                            showListings(markerData);
                        }
                        map.setCenter(generateLatLng(suggestion.data.location.lat, suggestion.data.location.lng));
                        map.setZoom(19);
                    } break;
                    case 'type': {
                        highlightBuilding(suggestion.data.buildingPath, suggestion.data.code);
                    } break;
                    default: break;
                }
                // mobileViewUpdate();
                $('#autocomplete').val('');
            }
        });
    }

    /*==========================================================================
       Highlight active sidebar menu items
    ==========================================================================*/
    // Highlight active sidebar menu items for main and other locations sidebar
    function setActiveMenuItem(container, item) {
        var btnContainer, btns, current;

        var setActive = function () {
            current = document.getElementsByClassName("active");
            if (current.length > 0) {
                current[0].className = current[0].className.replace(" active", "");
            }
            this.className += " active";
        };

        btnContainer = document.getElementById(container);
        btns = btnContainer.getElementsByClassName(item);
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", setActive);
        }
    }

    // Highlight active accordion menu item - Accordion dynamically added
    // used event bubbling from parent click event to target dynamic element
    function setActiveBldgMenuItem() {
        var btnContainer, btns, current;

        var setActiveBldg = function () {
            current = document.getElementsByClassName("bldgActive");
            if (current.length > 0) {
                current[0].className = current[0].className.replace(" bldgActive", "");
            }
            this.className += " bldgActive";
        };

        document.getElementById('side-menu').addEventListener('click', function(event){
            btns = document.getElementsByClassName('selectBuilding');
            for (var i = 0; i < btns.length; i++) {
                btns[i].addEventListener("click", setActiveBldg);
            }
        });
    }

    /*==========================================================================
       Generate and append templates
    ==========================================================================*/
    function renderTemplate(template_name, templateId, data){
        var accordionHTML = templates[template_name]({
            data: data
        });

        element = document.getElementById(templateId);
        element.innerHTML = accordionHTML;
    }

    function renderMarkerTemplate(template_name, boxText, data){
        var accordionHTML = templates[template_name]({
            data: data
        });

        boxText.innerHTML = accordionHTML;
    }

    renderTemplate('mainSideNav', 'side-nav-menu-items', {});
    renderTemplate('otherLocationsMenu', 'other-location-items', {});
    renderTemplate('searchForm', 'search-form', {});

    /*==========================================================================
        Helper Functions
    ==========================================================================*/
    // Generate Lat ang Lng
    function generateLatLng(lat, lng){
        return new google.maps.LatLng(lat, lng);
    }

    // returns an array of the variables from the path
    function getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    // Update browser url
    function UpdateURL(url) {
        if (!history.pushState) return;
        window.history.pushState({}, '', url);
    }

    // Removes directions polyline from the map before loading in new results
    function resetDirections(){
        if (common.directionsDisplay) {
            common.directionsDisplay.setMap(null);
        }
    }

    // Removes directions from the sidebar before loading in new results
    function resetTurnByTurnDirections(){
        if (common.directionsDisplay) {
            common.directionsDisplay.setPanel(null);
        }
    }

    function closeInfoBox(){
        common.infoBox.close();
    }

    // Change the state of sidebar to hide or show
    function toggleSidebar(state){
        $('.ui.sidebar').sidebar(''+state+'');
    }

    // Get first word from POI or building name
    function getFirstWord(word){
        return word.split(" ")[0];
    }

    // Get the first letter from word before sorting
    function letterBeginsWith(word, testLetters){
        if (word.substring(0, 1).match(testLetters)) {
            return true;
        }
    }

    // Sort list of words
    function listSort(list) {
        list.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    }

    // Remove highlight from active menu
    function clearActiveMenuItem() {
        var current = document.getElementsByClassName("active");
        var list = Array.from(current);
        list.forEach(function (activeItem) {
            activeItem.classList.remove("active");
        });
    }

    function updateLocationsWithoutLatLng(building) {
        switch(building.name){
            case "L3Harris Village, Bldg. C":
                building.location.lat = 28.058214;
                building.location.lng = -80.622142;
                break;
            case 'Southgate Apartment B':
                building.location.lat = 28.067155;
                building.location.lng = -80.620714;
                break;
            case 'Southgate Apartment C':
                building.location.lat = 28.067005;
                building.location.lng = -80.620175;
                break;
            case 'Southgate Apartment D':
                building.location.lat = 28.066598;
                building.location.lng = -80.620442;
                break;
            case 'Southgate Apartment E':
                building.location.lat = 28.066769;
                building.location.lng = -80.620985;
                break;
            case 'Southgate Apartment H':
                building.location.lat = 28.066066;
                building.location.lng = -80.620437;
                break;
            case 'Southgate Apartment L':
                building.location.lat = 28.065470;
                building.location.lng = -80.620432;
                break;
            case 'Southgate Pool Building':
                building.location.lat = 28.066330;
                building.location.lng = -80.620842;
                break;
            case 'Southgate Apartment K':
                building.location.lat = 28.065810;
                building.location.lng = -80.620405;
                break;
            case 'Southgate Apartment M':
                building.location.lat = 28.065822;
                building.location.lng = -80.621082;
                break;
            case "Building 402":
                building.location.lat = 28.066084;
                building.location.lng = -80.624053;
                break;
            case "Building 403":
                building.location.lat = 28.066084;
                building.location.lng = -80.623770;
                break;
            case "Microelectronics Lab":
                building.location.lat = 28.066094;
                building.location.lng = -80.623430;
                break;
            case "Dan Dahle Building":
                building.location.lat = 28.065497;
                building.location.lng = -80.624040;
                break;
            case "Building 406":
                building.location.lat = 28.065497;
                building.location.lng = -80.623780;
                break;
            case "Building 407":
                building.location.lat = 28.065497;
                building.location.lng = -80.623520;
                break;
            case "Ruth Funk Center For Textile Arts":
                building.location.lat = 28.066020;
                building.location.lng = -80.623190;
                break;
            case "Applied Research Laboratory":
                building.location.lat = 28.085795;
                building.location.lng = -80.627711;
                break;
            case "Anchorage  Roberts  Annex":
                building.location.lat = 28.076609;
                building.location.lng = -80.600600;
                break;
        }

    }

    // Convert lat, lng from string to number
    function convertPolygonLatLngToNumber(outline) {
        if (outline !== null) {
            for (var k = 0; k < outline.length; k++) {
                for (var l = 0; l < outline[k].length; l++) {
                    outline[k][l].lat = Number(outline[k][l].lat);
                    outline[k][l].lng = Number(outline[k][l].lng);
                }
            }
        }
    }

    // Reset the address bar url when map is reset
    function resetBaseUrl() {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        UpdateURL(baseUrl);
    }

    // Remove custom layers from map when map type is satellite
    function switchOnOffCustomLayers(overlay) {
        var type;
        var maptype = map.mapTypeId;
        if (maptype === 'roadmap') {
            overlay.setMap(map);
        } else if (maptype === 'hybrid') {
            overlay.setMap(null);
        }

        google.maps.event.addListener(map, 'maptypeid_changed', function () {
            type = map.getMapTypeId();
            if (type === 'roadmap') {
                overlay.setMap(map);
            } else if (type === 'hybrid') {
                overlay.setMap(null);
            }
        });
    }

    // Highlight selected building
    function highlightBuilding(path, code) {
        var poly, url;
        options.polygon.activeBuilding.paths = path;
        // options.polygon.building.strokeColor = '#009fda';
        poly = new google.maps.Polygon(options.polygon.activeBuilding);
        hidePolygon(poly);
        showPolygon(poly);
        url = location.pathname + '?location=' + code;
        if(code !== null){
            UpdateURL(url);
        }
    }

    // Used to show highlighted polygon on click
    function showPolygon(polygon) {
        common.highlightBuildingOutline.push(polygon);
        polygon.is_active = true;
        polygon.setMap(map);
    }

    // Used to hide highlighted polygon when new polygon clicked
    function hidePolygon() {
        if (common.highlightBuildingOutline.length != 0) {
            common.highlightBuildingOutline.forEach(function (building) {
                if (building.is_active === true) {
                    building.setMap(null);
                }
            });
        }
    }

    // Clears all markers from map
    function clearMarkers() {
        for (var i = 0; i < common.markers.length; i++) {
            common.markers[i].setMap(null);
        }
    }

    // Deletes all markers from map
    function deleteMarkers() {
        closeInfoBox();
        clearMarkers();
        common.markers = [];
    }

    // Delete the find me marker from the map
    function deleteFindMeMarker() {
        if (!_.isEmpty(common.locateMeMarker)){
            common.locateMeMarker.setMap(null);
            common.locateMeMarker = [];
        }
    }

    function showOtherLocationsMenu() {
        document.getElementById('side-nav-menu-items').style.display = 'none';
        document.getElementById('other-location-items').style.display = 'block';
        mapResetWithoutSidebar();
    }

    function hideBuildingList() {
        document.getElementById('side-nav-menu-items').style.display = 'block';
        document.getElementById('building-list').style.display = 'none';
    }

    function showBuildingList() {
        document.getElementById('side-nav-menu-items').style.display = 'none';
        document.getElementById('building-list').style.display = 'block';
        mapResetWithoutSidebar();
    }

    function hideOtherLocationsMenu() {
        document.getElementById('side-nav-menu-items').style.display = 'block';
        document.getElementById('other-location-items').style.display = 'none';
    }

    function showsearchFormMenu() {
        document.getElementById('side-nav-menu-items').style.display = 'none';
        document.getElementById('search-form').style.display = 'block';
        document.getElementById('other-location-items').style.display = 'none';
        document.getElementById('building-list').style.display = 'none';
        toggleSidebar('show');
    }

    function hidesearchFormMenu() {
        document.getElementById('side-nav-menu-items').style.display = 'block';
        document.getElementById('search-form').style.display = 'none';
    }

    function resetDefaultSidebar() {
        document.getElementById('other-location-items').style.display = 'none';
        document.getElementById('search-form').style.display = 'none';
        document.getElementById('building-list').style.display = 'none';
        document.getElementById('side-nav-menu-items').style.display = 'block';
    }

    function closeSidebarOnClick(){
        var toggle = function() { toggleSidebar('hide'); };

        common.sideNavElements = document.getElementsByClassName('testing');
        for (var i = 0; i < common.sideNavElements.length; i++) {
            common.sideNavElements[i].addEventListener("click", toggle);
        }
    }

    function mobileViewUpdate() {
        var viewportWidth = $(window).width();
        var mobileNav = document.getElementsByClassName('mobileNavList');
        var navigation = Array.from(mobileNav);

        navigation.forEach(function(item){
            item.classList.add('testing');
        });

        if (viewportWidth <= 769) {
            closeSidebarOnClick();
            toggleSidebar('hide');
        }

        if (viewportWidth > 770) {
            toggleSidebar('show');
            navigation.forEach(function(item){
                item.classList.remove('testing');
            });
        }
    }


    function setMapDefaultView(lng){
        options.center.lat = 28.064;
        options.center.lng = lng;
        map.setCenter(generateLatLng(28.064, lng));
    }

    function closeStreetView() {
        map.getStreetView().setVisible(false);
    }

    // Map reset
    function mapReset() {
        map.setCenter(generateLatLng(options.center.lat, options.center.lng));
        map.setZoom(options.zoom);
        deleteMarkers();
        deleteFindMeMarker();
        resetDirections();
        hidePolygon();
        resetBaseUrl();
        resetTurnByTurnDirections();
        resetDefaultSidebar();
        clearActiveMenuItem();
        closeStreetView();
    }

    // Reset map without resetting the sidebar
    function mapResetWithoutSidebar() {
        map.setCenter(generateLatLng(options.center.lat, options.center.lng));
        map.setZoom(options.zoom);
        deleteMarkers();
        deleteFindMeMarker();
        resetDirections();
        hidePolygon();
        resetBaseUrl();
        resetTurnByTurnDirections();
        clearActiveMenuItem();
    }

    init();
    /*==========================================================================
        Document ready function
    ==========================================================================*/
    $(function(){

        /*======================================================================
            Semantic UI Elements jQuery Actions
        ======================================================================*/
        $('.ui.inline.dropdown').dropdown();

        $('#responsive-menu-button').click(function () {
            $('.ui.modal').modal('show');

        });

        $('.s-button').click( function() {
            $("#search-area").toggleClass("disabled");
            $("#search-area input").focus();
        });

        $('.ui.simple.dropdown').dropdown({on: 'hover'});

        $('.ui.accordion').accordion();


        $(".s-button").click(function(){
            $("#search-area").toggle();
        });

        $('.s-button').click(function(){
            if ( $('.menu.floating').css('display') == 'none' ) {
                $( ".menu.floating" ).toggle( "slide", function() {
                    $('.menu.floating').css('display','block');
                });
            } else {
                $( ".menu.floating" ).toggle( "slide", function() {
                    $('.menu.floating').css('display','none');
                });
            }
        });

        window.addEventListener("resize", function() {
            if (window.matchMedia("(min-width: 769px)").matches) {
                $('.menu.floating').css('display','none');
            }
        });

        $(window).on("load", mobileViewUpdate);
        $(window).on("resize", mobileViewUpdate);

        $('.ui.sidebar')
            .sidebar({ context: $('.bottom.segment'), dimPage: false, closable: false })
            .sidebar('attach events', '.mapTopMenu');
    });
}(window, google));
